import React, { FC, ReactText, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiConnector } from '../../utils/apiConnector';
import { fireErrorNotification } from '../../utils/notification';
import { Button, ButtonColor, ButtonSize, Icon, IconName, Input, Tooltip } from '@tia/react-ui-library';
import PolicyStatus from '@tia/corporate-api-connector/dist/model/PolicyStatus';
import { LoadingRow } from './PolicyRow';
import { IPartyPolicy } from '@tia/corporate-api-connector/dist/model/IUnderlyingParty';

interface IDeleteButton {
  onDelete: () => void;
  policy: IPartyPolicy;
}

export const DeleteButton: FC<IDeleteButton> = (props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [cancelDateValue, setCancelDateValue] = useState<ReactText>('');

  const modalRef = useRef<HTMLElement>();

  const { t } = useTranslation();

  const closeModal = (): void => setShowConfirm(false);
  const onSubmit = (): void => {
    setShowConfirm(false);
    handleCancel(props.policy.sipasUuid);
  };

  useEffect(() => {
    const modalElement = modalRef.current;

    if (!modalElement) {
      return;
    }

    modalElement.addEventListener('close', closeModal);
    modalElement.addEventListener('cancel', closeModal);
    modalElement.addEventListener('primary', onSubmit);

    return (): void => {
      modalElement.removeEventListener('close', closeModal);
      modalElement.removeEventListener('cancel', closeModal);
      modalElement.removeEventListener('primary', onSubmit);
    };
  }, [cancelDateValue, showConfirm]);

  const handleCancel = async (sipasUuid: string): Promise<void> => {
    try {
      setLoading(true);
      const cancelPolicyResponse = await apiConnector().salesPolicies.cancelSalesPolicy(
        sipasUuid,
        new Date(cancelDateValue)
      );

      if (!cancelPolicyResponse.successful) {
        setLoading(false);
        cancelPolicyResponse.errors.forEach((error) => fireErrorNotification(error, t));

        return;
      }

      setLoading(false);
      props.onDelete();
    } catch (err) {
      setLoading(false);
      fireErrorNotification(err, () => null);
      console.error('TCL: handleCancel -> err', err);
    }
  };

  if (loading) {
    return <LoadingRow />;
  }

  return (
    <>
      <tia-modal
        title={t('employeesTable.cancelPolicyModal.title')}
        id="policy-cancel-modal"
        canceltext={t('employeesTable.cancelPolicyModal.cancelButton')}
        primarytext={t('employeesTable.cancelPolicyModal.acceptButton')}
        headericon="cancel"
        visible={showConfirm}
        ref={modalRef}
      >
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <Tooltip placement="top" tooltipText={t('employeesTable.cancelPolicyModal.fieldTooltip')}>
            <Icon icon={IconName.INFO}></Icon> {t('employeesTable.cancelPolicyModal.fieldLabel')}
          </Tooltip>
        </div>
        <Input onChange={setCancelDateValue} type="date" />
      </tia-modal>
      <Button
        onClick={(): void => setShowConfirm(true)}
        icon={<Icon icon={IconName.DELETE} />}
        type={'button'}
        color={ButtonColor.MINIMAL}
        size={ButtonSize.LARGE}
        disabled={!(props.policy.policyStatus === PolicyStatus.POLICY) || props.policy.sipasUuid === null}
      />
    </>
  );
};
