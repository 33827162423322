import { TiaAuthClient, User } from '@tia/authenticator';
import { useEffect, useMemo, useState } from 'react';
import { getAuthOptions } from '../components/auth/CorporateAuthProvider';
import { getUserIdentityTypes } from '../utils/UserContext';

export const TCP_ACCESS_TOKEN = 'tia.widgets.tcp.accessToken';

export const useTwoWayLogin = (userId: string) => {
  const TCP_EXPIRES_AT = 'tia.widgets.tcp.expiresAt';
  const authOptions = useMemo(getAuthOptions, []);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const twoWay = new TiaAuthClient({
      authOptions: {
        domain: authOptions.domain,
        successfulLogoutUri: authOptions.successfulLogoutUri,
        audience: window.corpEnv.twoWayCommunicationWidgetAudienceApi,
        clientId: authOptions.clientID,
        redirectUri: `${window.location.origin}/callback`,
        scope: authOptions.scope,
        webStorageStateName: 'tcp',
      },
    });

    const login = async () => {
      try {
        const user = await twoWay.silentLogin({ corporatefirm: userId });
        if (user) {
          localStorage.setItem(TCP_ACCESS_TOKEN, user.access_token);
          localStorage.setItem(TCP_EXPIRES_AT, `${user.expires_at * 1000}`);
          setIsAuthenticated(true);
        }
      } catch (e) {
        console.error(e);
        setIsAuthenticated(true);
      }
    };

    (async () => {
      const tcpUser = await twoWay.getUser();
      if (tcpUser) {
        if (getUserIdentityTypes(tcpUser).corporatefirm.user_id !== userId) {
          await login();
        }
        setIsAuthenticated(true);
      } else {
        await login();
      }
    })();

    twoWay.addOnTokenRenewListener((user: User) => {
      if (user) {
        localStorage.setItem(TCP_ACCESS_TOKEN, user.access_token);
        localStorage.setItem(TCP_EXPIRES_AT, `${user.expires_at * 1000}`);
      }
    });
  }, [userId]);

  return isAuthenticated;
};
