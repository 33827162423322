import { Badge } from '@tia/react-ui-library';
import React, { FC, useContext, useEffect, useState } from 'react';
import { TCP_ACCESS_TOKEN } from '../../hooks/useTwoWayLogin';
import { TwoWayContext } from '../../utils/TwoWayContext';
import './contactUsBadge.css';

export const ContactUsBadge: FC<{ menuClicked: boolean }> = ({ menuClicked }) => {
  const [counter, setCounter] = useState(0);
  const refreshTime = (parseInt(window.corpEnv.twoWayBadgeRefreshTime, 10) || 30) * 1000;

  const { message } = useContext(TwoWayContext);

  const getUnreadMessages = async () => {
    try {
      const resp = await fetch(
        `${window.corpEnv.twoWayCommunicationWidgetApi}/me/two-way-communications/unreadMessageCount`,
        { headers: { authorization: `Bearer ${localStorage.getItem(TCP_ACCESS_TOKEN)}` } }
      );
      const response = await resp.json();
      setCounter(response.content.messageCount || 0);
    } catch {
      setCounter(0);
    }
    setTimeout(getUnreadMessages, refreshTime);
  };

  useEffect(() => {
    getUnreadMessages();
  }, [menuClicked, message]);

  return (
    <div className=".TinAw .TinAw.type--primary">
      <Badge count={counter} showZero={false} />
    </div>
  );
};
