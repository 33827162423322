import React, { Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import Dashboard from './views/Dashboard/Dashboard.view';
import Admin from './views/Admin/Administrate/Admin.view';
import AppContainer from './components/appContainer/AppContainer.view';
import { TcpRouteGuard } from './utils/TcpRoute';
import Policy from './views/Dashboard/Policy/Policy.view';
import { getUserIdentityTypes, getUserPermissions, UserContext } from './utils/UserContext';
import EmployeeDetails from './views/Employees/EmployeeDetails/EmployeeDetails.view';
import EditEmployee from './views/Employees/EditEmployee/EditEmployee.view';
import EditCorporation from './views/Dashboard/EditCorporationn/EditCorporation.view';
import NewQuoteView from './views/Employees/NewQuote/NewQuote.view';
import CreateEmployee from './views/Employees/CreateEmployee/CreateEmployee';
import Employees from './views/Employees/Employees.view';
import EmployeePolicy from './views/Employees/EmployeePolicy/EmployeePolicy.view';
import { Routes } from './routes';
import { apiConnector } from './utils/apiConnector';
import IParty from '@tia/corporate-api-connector/dist/model/IParty';
import { getLanguage, setLanguage } from './utils/language';
import { LoadingIndicator } from './components/loadingIndicator/LoadingIndicator';
import { RemoteCorporateWidgetsBundleLoader } from './components/remoteResource/RemoteCorporateWidgetsBundleLoader';
import { RemoteTiaWidgetsBundleLoader } from './components/remoteResource/RemoteTiaWidgetsBundleLoader';
import { CorporateAuthProvider } from './components/auth/CorporateAuthProvider';
import { useTiaAuth } from '@tia/authenticator';
import { User } from 'oidc-client';
import { SessionTimeoutComponent } from './components/inactivity/SessionTimeout.component';
import { ContactUs } from './views/ContactUs/ContactUs.view';
import { useTwoWayLogin } from './hooks/useTwoWayLogin';
import { twoWayContextDefaultState, TwoWayContext } from './utils/TwoWayContext';

const AppComponent = () => {
  const { user } = useTiaAuth();
  const [state, setState] = useState({
    partyDetails: {} as IParty,
    isMenuExpanded: true,
    identityTypes: getUserIdentityTypes(user as User),
    identityPermissions: getUserPermissions(user as User),
  });

  const handleExpand = (): void => {
    setState({ ...state, isMenuExpanded: !state.isMenuExpanded });
  };

  const [twoWayContext, setTwoWayContext] = useState(twoWayContextDefaultState.message);

  useEffect(() => {
    try {
      apiConnector()
        .party.getPartyMe(state.identityTypes.corporateagent.user_id)
        .then((r) => {
          setState({ ...state, partyDetails: r.data });
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  let twoWayLoggedIn;
  if (state.identityTypes.corporatefirm.user_id) {
    twoWayLoggedIn = useTwoWayLogin(state.identityTypes.corporatefirm.user_id);
  }

  if (!twoWayLoggedIn) {
    return <LoadingIndicator />;
  }

  return (
    <UserContext.Provider value={state.identityTypes}>
      <TwoWayContext.Provider
        value={{
          message: twoWayContext,
          sendMessage: () => setTwoWayContext(!twoWayContext),
        }}
      >
        <AppContainer
          identityPermissions={state.identityPermissions}
          partyDetails={state.partyDetails}
          isMenuExpanded={state.isMenuExpanded}
          toggleIsMenuExpanded={handleExpand}
        >
          <Switch>
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={true}
              component={Dashboard}
              path={Routes.Dashboard}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EditCorporation}
              path={Routes.EmployeesEditCorporation}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EditCorporation}
              path={Routes.DashboardEditCorporation}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={false}
              isRoot={false}
              component={Policy}
              path={Routes.DashboardViewPolicy}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_ADMIN']}
              exact={true}
              isRoot={false}
              component={Admin}
              path={Routes.Admin}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={Employees}
              path={Routes.Employees}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={CreateEmployee}
              path={Routes.EmployeesNewEmployee}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EditEmployee}
              path={Routes.EmployeesEditEditEmployee}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={NewQuoteView}
              path={Routes.EmployeesNewQuote}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EmployeePolicy}
              path={Routes.EmployeesViewPolicy}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EmployeePolicy}
              path={Routes.EmployeesViewPolicyFallback}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={EmployeeDetails}
              path={Routes.EmployeesViewParty}
            />
            <TcpRouteGuard
              identityPermissions={state.identityPermissions}
              acceptedIdentityPermissions={['ROLE_AGENT']}
              exact={true}
              isRoot={false}
              component={ContactUs}
              path={Routes.ContactUs}
            />
            <Redirect exact={true} from="*" to={useRedirect(state.identityPermissions)} />
          </Switch>
        </AppContainer>
      </TwoWayContext.Provider>
    </UserContext.Provider>
  );
};

const setDefaultLanguage = (): void => {
  let selectedLanguage = getLanguage();
  const supportedLanguages = window.corpEnv.supportedLanguages;

  if (
    (selectedLanguage === 'nn' && !supportedLanguages.includes('nn')) ||
    (selectedLanguage === 'nb' && !supportedLanguages.includes('nb'))
  ) {
    setLanguage('no');
    selectedLanguage = 'no';
  }

  if (selectedLanguage && supportedLanguages && !supportedLanguages.split(',').includes(selectedLanguage)) {
    setLanguage('tia');
  }
};

const useRedirect = (identityPermissions: string[]): string => {
  if (identityPermissions.indexOf('ROLE_AGENT') === -1) {
    return Routes.Admin;
  }

  return Routes.Dashboard;
};

export default function App(): JSX.Element {
  const [resources, setResources] = useState({ tiaWidgets: false, corporateWidgets: false, twoWay: false });

  useLayoutEffect(() => {
    setDefaultLanguage();
  }, []);

  const handleTiaWidgetLoad = () => setResources((oldState) => ({ ...oldState, tiaWidgets: true }));
  const handleCorporateWidgetLoad = () => setResources((oldState) => ({ ...oldState, corporateWidgets: true }));

  const contentIsReady = resources.corporateWidgets && resources.tiaWidgets;

  return (
    <Suspense fallback={<AppLoader />}>
      <BrowserRouter basename={window.corpEnv.contextPath}>
        <CorporateAuthProvider>
          <SessionTimeoutController />
          <RemoteCorporateWidgetsBundleLoader onLoad={handleCorporateWidgetLoad} />
          <RemoteTiaWidgetsBundleLoader onLoad={handleTiaWidgetLoad} />
          {!contentIsReady && <LoadingIndicator />}
          {contentIsReady && <AppComponent />}
        </CorporateAuthProvider>
      </BrowserRouter>
    </Suspense>
  );
}

const SessionTimeoutController = () => {
  const { logout } = useTiaAuth();
  const { current: sessionTimeout } = useRef(getSessionTimeout());
  const { current: sessionModalTimeout } = useRef(getSessionExpiringModalTimeout());

  return <SessionTimeoutComponent timeout={sessionTimeout} modalTimeout={sessionModalTimeout} onInactive={logout} />;
};

const getSessionTimeout = (): number => (parseInt(window.corpEnv.sessionTimeout, 10) || 0) * 1000;
const getSessionExpiringModalTimeout = (): number =>
  (parseInt(window.corpEnv.sessionExpiringModalTimeout, 10) || 0) * 1000;

/**
 * Component cannot be translated as it is a placeholder for lazy loaded content.
 * In this case - translations
 * @constructor
 */
const AppLoader: React.FC = () => {
  return <LoadingIndicator>Loading...</LoadingIndicator>;
};
