import React, { useMemo } from 'react';
import { AuthProvider } from '@tia/authenticator';
import { User } from 'oidc-client';
import { Unauthenticated } from './Unauthenticated';
import { LoadingIndicator } from '../loadingIndicator/LoadingIndicator';
import { getUserIdentityTypes } from '../../utils/UserContext';

const ID_TOKEN = 'id_token';
const AGENT_TOKEN = 'tiaagent_access_token';
const CORPORATE_TOKEN = 'tiacorporate_access_token';

const handleOnTokenRenew = (user: User) => {
  if (user) {
    const accessToken = user.access_token;
    const idToken = user.id_token;
    localStorage.setItem(ID_TOKEN, idToken);
    localStorage.setItem(AGENT_TOKEN, accessToken);
    localStorage.setItem(CORPORATE_TOKEN, accessToken);
  }
};

export const CorporateAuthProvider: React.FC = ({ children }) => {
  const authOptions = useMemo(getAuthOptions, []);

  return (
    <AuthProvider
      UnauthenticatedState={Unauthenticated}
      LoadingState={LoadingIndicator}
      domain={authOptions.domain}
      logoutUri={authOptions.successfulLogoutUri}
      audience={authOptions.audience}
      clientId={authOptions.clientID}
      redirectUri={`${window.location.origin}/callback`}
      onTokenRenew={handleOnTokenRenew}
      getSignInSilentParams={getSignInSilentParams}
      onLogout={() => {
        localStorage.removeItem(ID_TOKEN);
        localStorage.removeItem(AGENT_TOKEN);
        localStorage.removeItem(CORPORATE_TOKEN);
      }}
      scope="openid profile offline_access"
    >
      {children}
    </AuthProvider>
  );
};

const getSignInSilentParams = (user: User) => {
  const identityTypes = getUserIdentityTypes(user);

  return {
    corporatefirm: identityTypes.corporatefirm.user_id,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAuthOptions = () => {
  const { domain, clientID, audience } = window.corpEnv;

  if (!domain) {
    throw Error('Domain configuration is missing');
  }

  if (!clientID) {
    throw Error('clientID configuration is missing');
  }

  const windowLocation = window.location.origin;
  const contextPath = window.corpEnv.contextPath || '';
  const contextLocation = contextPath ? `${windowLocation}/${contextPath}` : windowLocation;

  return {
    clientID,
    audience,
    domain: `https://${domain}`,
    redirectUri: `${contextLocation}/callback`,
    successfulLogoutUri: `${contextLocation}/logout`,
    responseType: process.env.REACT_APP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_SCOPE,
    tokenKey: [CORPORATE_TOKEN, AGENT_TOKEN],
  };
};
